import React, { Component } from 'react';

import MapPage from './MapPage.js'
import PlacePage from './PlacePage.js'
import Menu from './Menu.js'

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import axios from 'axios';
import { apiUrl } from '../helpers'
import { t } from '../helpers'

import styled from 'styled-components'
import { createGlobalStyle } from "styled-components";

const uuidv1 = require('uuid/v1');

class BaseContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      places: [],
      currentPlace: null,
      menuOpen: false,
      translations: [],
      locale: "en",
      userId: uuidv1()
    }
  }

  async componentDidMount() {
    disableBodyScroll(document.querySelector('body'))

    let response = await axios.get(apiUrl + "/place");
    this.setState({places: response.data.docs});

    response = await axios.get(apiUrl + "/translation");
    this.setState({translations: response.data.docs});



    await axios.post(apiUrl + "/log_entry", {
      event: "site loaded", 
      userId: this.state.userId
    });
  }

  setLocale = (l)=> {
    this.setState({locale: l})
  }

  render() {
    return (
      [<GlobalStyles key="globalstyles" />,
      <div id="main" key="main">
        {!this.state.menuOpen && !this.state.currentPlace &&
          [<MenuButton src="/images/menu.svg" onClick={()=>{
            this.setState({menuOpen: true});
            }}/>,
            <MainHeadline>{this.state.translations.length ? 
              t(this.state.translations, "main_headline", this.state.locale) : ""}</MainHeadline>
          ]
        }

        {this.state.menuOpen && 
          <Menu 
            close={()=>{ this.setState({menuOpen: false}); }}
            translations={this.state.translations}
            locale={this.state.locale}
            setLocale={this.setLocale}
          />
        }



        <MapPage
          visible={!this.state.currentPlace} 
          places={this.state.places}
          openPlace={(p)=>{this.setState({currentPlace: p})}}
          locale={this.state.locale}
        />
        {this.state.currentPlace && 
          <PlacePage 
            place={this.state.currentPlace}
            exitPlace={()=>{this.setState({currentPlace: null})}}
            locale={this.state.locale}
            translations={this.state.translations}
            userId={this.state.userId}
          />
        }
      </div>]
    );
  }
}

export default BaseContainer;

const MenuButton = styled.img`
  position: absolute;
  z-index: 100;
  width: 30px;
  height: 30px;
  left: 20px;
  top: 20px;
  :hover {cursor: pointer}; 
`

const MainHeadline = styled.h1`
  position: absolute;
  z-index: 100;
  left: 65px;
  top: 16px;
  margin: 0;
  font-family: thin;
  font-size: 31px;
  text-transform: uppercase;  
  font-weight: 100;
`


const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: thin;
    src:  url('/fonts/thin.ttf');
    /*[unicode-range: <urange>#;]?
    [font-variant: <font-variant>;]?
    [font-feature-settings: normal|<feature-tag-value>#;]?
    [font-stretch: <font-stretch>;]?
    [font-weight: <weight>];
    [font-style: <style>];*/
  }

  @font-face {
    font-family: light;
    src:  url('/fonts/light.ttf');
    /*[unicode-range: <urange>#;]?
    [font-variant: <font-variant>;]?
    [font-feature-settings: normal|<feature-tag-value>#;]?
    [font-stretch: <font-stretch>;]?
    [font-weight: <weight>];
    [font-style: <style>];*/
  }

  @font-face {
    font-family: regular;
    src:  url('/fonts/regular.ttf');
    /*[unicode-range: <urange>#;]?
    [font-variant: <font-variant>;]?
    [font-feature-settings: normal|<feature-tag-value>#;]?
    [font-stretch: <font-stretch>;]?
    [font-weight: <weight>];
    [font-style: <style>];*/
  }

  @font-face {
    font-family: italic;
    src:  url('/fonts/italic.ttf');
    /*[unicode-range: <urange>#;]?
    [font-variant: <font-variant>;]?
    [font-feature-settings: normal|<feature-tag-value>#;]?
    [font-stretch: <font-stretch>;]?
    [font-weight: <weight>];
    [font-style: <style>];*/
  }


  html, body, #root, #main {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  body {
    font-family: regular, sans-serif;
  }
`

import React, { Component } from 'react';
import styled from 'styled-components'

import { t } from '../helpers'
import ExitButton from './ExitButton';



class LocaleToggle extends Component {
  render () {
    const localeOptions = ["en", "no"].map((l)=>
      <LocaleOption active={l == this.props.locale} onClick={()=>this.props.setLocale(l)}><span>{l}</span></LocaleOption>
    )
    return (
      <div>
        {/*<p>{t(this.props.translations, "language_prompt", this.props.locale)}</p>*/}
        <LocaleContainer>
          {localeOptions}        
        </LocaleContainer>
      </div>
    )
  }
}

const LocaleContainer = styled.ul`
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: 40px;
`

const LocaleOption = styled.li`
  text-decoration: none;
  list-style: none;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "thin";
  span { 
    font-family: ${props=>props.active ? "light" : "thin"}; 
    padding: 5px;
  }
  :hover {cursor: ${props=>props.active ? "default" : "pointer"}};
  :first-child {:after { content: "/" }};
  display: inline-block;
`


class Menu extends Component {

  constructor(props) {
    super(props);

    let menuSections = [];
    this.props.translations.forEach((t)=>{
      let myRegexp = /(menu_)(\d+)(_title)/g;
      let match = myRegexp.exec(t.key);
      if(match) {
        if(match.length == 4) {
          menuSections.push({
            open: false,
            titleKey: t.key,
            contentKey: "menu_" + match[2] + "_content",
            order: match[2]
          });  
        }  
      }
    });
    menuSections.sort((a,b)=>a.order - b.order);
    this.state = { menuSections }
  }

  toggleSection = (index)=>{
    let menuSections = this.state.menuSections;
    menuSections[index].open = !menuSections[index].open;
    this.setState({ menuSections });
  }
  
  render () {

    const menuItems = this.state.menuSections.map((s, index)=>
      <MenuSection>
        <MenuSectionTitle 
          onClick={()=>{this.toggleSection(index)}}
        >
          <MenuIcon src={s.open ? "/images/Menu_open.svg" : "/images/Menu_close.svg"}/>
          {t(this.props.translations, s.titleKey, this.props.locale)}
        </MenuSectionTitle>
        {s.open && 
          <MenuContent dangerouslySetInnerHTML={{__html: t(this.props.translations, s.contentKey, this.props.locale)}}/>
        }
      </MenuSection>
    )

    return (
      [<PlacePageBackground onClick={this.props.close}/>,
      <MenuContainer>
        
        <ExitButton
          onClick={this.props.close}
          translations={this.props.translations}
          locale={this.props.locale}
        />

        <ScrollContent>
        
        {<LocaleToggle 
          locale={this.props.locale} 
          setLocale={this.props.setLocale}
          translations={this.props.translations}
        />}
        {/*<Headline>{t(this.props.translations, "main_title", this.props.locale)}</Headline>*/}
        {menuItems}

        </ScrollContent>

      </MenuContainer>]
    )
  }
}

export default Menu;

const ScrollContent = styled.div`
  overflow-y: scroll;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
`

const Headline = styled.h1`
  margin-top: 0px;
`

const MenuSectionTitle = styled.h3`
 :hover {cursor: pointer}; 
 user-select: none;
 text-transform: uppercase;
 font-family: thin, sans-serif;
 font-size: 29px;
 font-weight: 100;
 margin-bottom: 10px;
 margin-top: 10px;
`

const MenuSection = styled.div`
 :last-child { margin-bottom: 40px; }
`

const MenuIcon = styled.img`
  width: 20px;
  height: 20px;
  position: relative;
  top: 3px;
  padding-right: 5px;
`

const MenuContent = styled.p`
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 40px;
`

const PlacePageBackground = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;  
  z-index: 10;
`


const MenuContainer = styled.div`
  position: absolute;
  z-index: 50;
  box-sizing: border-box;
  width: 50%;
  min-height: 100vh;
  padding: 0px;
  background-color: white;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

`
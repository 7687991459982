import React, { Component } from 'react';
import styled from 'styled-components'

import { t } from '../helpers'

class ExitButton extends Component {
 
  render() {
    return(
      <ExitButtonContainer
        onClick={this.props.onClick}
      >
        {/*<ExitButtonImage src="/images/close.png"/>*/}
        <ExitButtonHelp>{t(this.props.translations, "back_to_map", this.props.locale)}</ExitButtonHelp>
      </ExitButtonContainer>
    )
  }
}

export default ExitButton;

const ExitButtonContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 30px;
  :hover {cursor: pointer};
`

const ExitButtonImage = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0px;
`
const ExitButtonHelp = styled.div`
  position: absolute;
  top: 30px;
  right: 0px;
  font-family: light;
  text-transform: uppercase;
  border: 1px solid black;
  padding: 10px;
  background-color: white;
  white-space: nowrap;
`